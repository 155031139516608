import {
  isArray,
  isArrayOfType,
  isBoolean,
  isNil,
  isObject,
  isString,
  type Nil,
} from "@mcwd/typescript-type-guards";
import type { LanguageCountryLocale } from "./types-and-definitions/locale-defs/Locales.js";
import type { LinkDownloadType } from "./tracking/LinkDownloadType.js";

interface TypeCheckFnGeneric<OrigType, T extends OrigType> {
  (value: OrigType): value is T;
}

interface TypeCheckFn<T> extends TypeCheckFnGeneric<unknown, T> {
  (value: unknown): value is T;
} 

interface TypeCheckFnGenericWithArgs<OrigType, T extends OrigType> {
  (value: OrigType, ...args: any[]): value is T;
}


interface TypeCheckFnWithArgs<T> extends TypeCheckFnGenericWithArgs<unknown, T> {
  (value: unknown, ...args: any[]): value is T;
}

interface TypeCheckFnWithSingleArg<T, A1> extends TypeCheckFnWithArgs<T> {
  (value: unknown, arg1: A1, ...args: any[]): value is T;
}

function hasPropWithType<TKey extends string, T>(
  val: object,
  prop: TKey,
  typeCheckFn: TypeCheckFn<T>
): val is Record<TKey, T> {
  return prop in val && typeCheckFn(val[prop as string]);
}

function hasOptionalPropWithType<TKey extends string, T>(
  val: object,
  prop: TKey,
  typeCheckFn: TypeCheckFn<T>
): val is Partial<Record<TKey, T>> {
  return !(prop in val) || typeCheckFn(val[prop as string]);
}

function checkConditionOrThrow<T, T2 extends T>(value: T, condition: ((val: T) => boolean), throwOnMissing: boolean, errorMessage: string): value is T2 {
  if (condition(value)) {
    return true;
  }
  else if (throwOnMissing) {
    throw new Error(errorMessage);
  }
  else return false;
}

function checkIsTypeOrThrow<T, T2 extends T>(value: T, condition: TypeCheckFnGeneric<T, T2>, throwOnMissing: boolean, errorMessage: string): value is T2 {
  return checkConditionOrThrow<T, T2>(value, condition, throwOnMissing, errorMessage);
}

function formatErrorMessage(messageLabel: string, ...errors: unknown[]) {
  const message = errors.map(err => {
    return ((err as Error)?.message ?? "ERROR IS NULL")
      ?.split('\n')
      ?.map(e => `  ${e}`) // Indent
      .join('\n');
  }).join("\n\n"); // Double space between errors
  return `${messageLabel}: \n${message}`;
}


const InternalDataVersion = ["Create", "Parse", "Final"] as const;
type InternalDataVersion = (typeof InternalDataVersion)[number];

const UserDataVersion = ["Create", "Final"] as const satisfies readonly InternalDataVersion[];
type UserDataVersion = (typeof UserDataVersion)[number];

export const DataVersion = UserDataVersion;
export type DataVersion = UserDataVersion;

interface BaseResource {
  id: string;
  legacyId: string;
  salesforceOfferId?: string | Nil;
  title: string;
  gatingLevel: "Full Gate" | "Partial Gate" | "Ungated";
  language: "en" | Exclude<LanguageCountryLocale, "en-US">
}

export interface DocumentResource extends BaseResource {
  description?: string | Nil;
  docType: string;
  icon?: string | Nil;
  iconAltText?: string | Nil;
  thumbnail?: string | Nil;
  thumbnailAltText?: string | Nil;
  CoverImage?: string | Nil;
  AltText?: string | Nil;
}

export interface VideoResource extends BaseResource {
  description: string,
  youTubeId: string;
  runtime?: string | Nil;
}

export interface VideoResourceWithDocs extends BaseResource, VideoResource {
  documentList: DocumentResource[];
}

function isBaseResource(val: unknown, throwOnMissing = false): val is BaseResource {
  return (
    checkIsTypeOrThrow(val, isObject, throwOnMissing, `resource is not an object`) &&
    checkConditionOrThrow(val, x => hasPropWithType(x, "id", isString), throwOnMissing, `resource does not gave property 'id' of type string`) &&
    checkConditionOrThrow(val, x => {
      return hasOptionalPropWithType(x, "legacyId", isString) || hasOptionalPropWithType<"legacyId", Nil>(val, "legacyId", isNil);
    }, throwOnMissing, `resource does not have property 'legacyId' of type string | Nil`) &&
    checkConditionOrThrow(val, x => hasPropWithType(x, "title", isString), throwOnMissing, `resource does not have property 'title' of type string`) 
    //checkConditionOrThrow(val, x => hasOptionalPropWithType(x, "salesforceOfferId", isString), throwOnMissing, `resource does not have property 'salesforceOfferId' of type string`)
  );
}

function isDocument(val: unknown, throwOnMissing = false): val is DocumentResource {
  try {
    return checkIsTypeOrThrow(val, ((x: unknown) => isBaseResource(x, throwOnMissing)) as TypeCheckFn<BaseResource>, throwOnMissing, `document is not of type BaseResource`) &&
      checkConditionOrThrow(val, x => hasOptionalPropWithType(x, "description", (y => (isString(y) || isNil(y))) as TypeCheckFn<string | Nil>), throwOnMissing, `document does not have property 'description' of type string | Nil`) &&
      checkConditionOrThrow(val, x => hasPropWithType(x, "docType", isString), throwOnMissing, `document does not have property 'docType' of type string`) &&
      checkConditionOrThrow(val, x => hasPropWithType(x, "icon", isString), throwOnMissing, `document does not have property 'icon' of type string`);
  }
  catch (err: unknown) {
    throw new Error(formatErrorMessage("isDocument", err), { cause: err });
  }
}

function isVideoOnly(val: unknown): val is VideoResource {
  return isBaseResource(val) && hasPropWithType(val, "youTubeId", isString);
}

function isVideoWithDocs(val: unknown): val is VideoResourceWithDocs {
  return isVideoOnly(val) && hasPropWithType(val, "documentList", isDocumentArray);
}

export function isDocumentArray(val: unknown): val is DocumentResource[] {
  return isArrayOfType<DocumentResource>(val, isDocument);
}

export function isVideoArray(val: unknown): val is VideoResource[] {
  return isArrayOfType<VideoResource>(val, isVideoOnly);
}

export function isVideoWithDocsArray(val: unknown): val is VideoResourceWithDocs[] {
  return isArrayOfType<VideoResourceWithDocs>(val, isVideoWithDocs);
}

interface CreateDocumentObjectProperties {
  isFilteredOrChosen?: boolean;
  selectedListWillBeSingleDocument?: boolean;
  sourceList: DocumentResource[];
}

type ParsedDocumentObjectProperties = Required<CreateDocumentObjectProperties>;

interface CreateVideoObjectProperties<TVideo extends VideoResource | VideoResourceWithDocs> {
  isFilteredOrChosen?: boolean;
  sourceList: TVideo[];
}

type ParsedVideoObjectProperties<TVideo extends VideoResource | VideoResourceWithDocs> = Required<CreateVideoObjectProperties<TVideo>>;

interface SelectedSingleResource<TResource extends DocumentResource | VideoResource | VideoResourceWithDocs> {
  selected: TResource;
}

interface SelectedMultiResource<TResource extends DocumentResource | VideoResource | VideoResourceWithDocs> {
  selected: TResource[];
}

function isCreateDocumentObjectProperties(val: unknown): val is CreateDocumentObjectProperties {
  return (
    isObject(val) &&
    hasOptionalPropWithType(val, "isFilteredOrChosen", isBoolean) &&
    hasOptionalPropWithType(val, "selectedListWillBeSingleDocument", isBoolean) &&
    hasPropWithType(val, "sourceList", isDocumentArray)
  );
}

function isParsedDocumentObjectProperties(
  val: unknown
): val is ParsedDocumentObjectProperties {
  return (
    isObject(val) &&
    hasPropWithType(val, "isFilteredOrChosen", isBoolean) &&
    hasPropWithType(val, "selectedListWillBeSingleDocument", isBoolean) &&
    hasPropWithType(val, "sourceList", isDocumentArray)
  );
}

function isSelectedSingleResource<
  TResource extends DocumentResource | VideoResource | VideoResourceWithDocs
>(
  val: unknown,
  typeCheckFn: TypeCheckFn<TResource>
): val is SelectedSingleResource<TResource> {
  return isObject(val) && hasPropWithType(val, "selected", typeCheckFn);
}

function isSelectedMultiResource<TResource extends DocumentResource | VideoResource | VideoResourceWithDocs>(
  val: unknown,
  typeCheckFn: TypeCheckFnWithSingleArg<TResource, boolean>,
  throwOnMissing = false,
): val is SelectedMultiResource<TResource> {
  try {
    return checkIsTypeOrThrow(val, isObject, throwOnMissing, `selectedMultiResource<> is not an object`) &&
      checkConditionOrThrow<object, { selected: unknown[] }>(val, x => hasPropWithType(x, "selected", isArray), throwOnMissing, `selectedMultiResource<>.selected is not an array: ${JSON.stringify(val)}`) &&
      checkConditionOrThrow<object, TResource[]>(
        val.selected,
        x => isArrayOfType<TResource>(x, y => typeCheckFn(y, throwOnMissing)),
        throwOnMissing,
        `selectedMultiResource<>.selected is not of type TResource[]: ${JSON.stringify(val.selected)}`
      );
  }
  catch(err: unknown) {
    throw new Error(formatErrorMessage("isSelectedMultiResource", err), { cause: err });
  }
}

function isCreateVideoObjectProperties(
  val: unknown
): val is CreateVideoObjectProperties<VideoResource> {
  return (
    isObject(val) &&
    hasOptionalPropWithType(val, "isFilteredOrChosen", isBoolean) &&
    hasPropWithType(val, "sourceList", isVideoArray)
  );
}

function isCreateVideoWithDocsObjectProperties(
  val: unknown
): val is CreateVideoObjectProperties<VideoResourceWithDocs> {
  return (
    isObject(val) &&
    hasOptionalPropWithType(val, "isFilteredOrChosen", isBoolean) &&
    hasPropWithType(val, "sourceList", isVideoWithDocsArray)
  );
}

function isParsedVideoObjectProperties(
  val: unknown
): val is ParsedVideoObjectProperties<VideoResource> {
  return (
    isObject(val) &&
    hasPropWithType(val, "isFilteredOrChosen", isBoolean) &&
    hasPropWithType(val, "sourceList", isVideoArray)
  );
}

function isParsedVideoWithDocsObjectProperties(
  val: unknown
): val is ParsedVideoObjectProperties<VideoResourceWithDocs> {
  return (
    isObject(val) &&
    hasPropWithType(val, "isFilteredOrChosen", isBoolean) &&
    hasPropWithType(val, "sourceList", isVideoWithDocsArray)
  );
}

interface Widget {
  name: string;
  instanceId: string;
  variation?: string | Nil;
}

function isWidget(val: unknown): val is Widget {
  return (
    isObject(val) &&
    hasPropWithType(val, "name", isString) &&
    hasPropWithType(val, "instanceId", isString) &&
    (hasOptionalPropWithType(val, "variation", isString) || hasOptionalPropWithType(val, "variation", isNil))
  );
}

export const FormSetupType = ["ResourceForm", "VideoForm", "GatedVideoForm", "ContactForm", "PostEventForm", "MaturityForm"] as const;
export type FormSetupType = (typeof FormSetupType)[number];

export const SetupType = ["YouTube", ...FormSetupType] as const;
export type SetupType = (typeof SetupType)[number];

function isFormSetupType(val: unknown): val is FormSetupType {
  return isString(val) && FormSetupType.includes(val as FormSetupType);
}

function isSetupType(val: unknown): val is SetupType {
  return isString(val) && SetupType.includes(val as SetupType);
}

interface CreateFormTrackingObject {
  usePageOfferId?: boolean | Nil;
  offerId?: string | Nil;
  ctaText?: string | Nil;
  ctaType?: string | Nil;
}

export interface FinalFormTrackingObject {
  usePageOfferId: boolean;
  offerId?: string | Nil;
  ctaText?: string | Nil;
  ctaType?: LinkDownloadType | Nil;
}

function isCreateFormTrackingObject(
  val: unknown
): val is CreateFormTrackingObject {
  return (
    isObject(val) &&
    (hasOptionalPropWithType(val, "usePageOfferId", isString) || hasOptionalPropWithType(val, "usePageOfferId", isNil)) &&
    (hasOptionalPropWithType(val, "offerId", isString) || hasOptionalPropWithType(val, "offerId", isNil)) &&
    (hasOptionalPropWithType(val, "ctaText", isString) || hasOptionalPropWithType(val, "ctaText", isNil)) &&
    (hasOptionalPropWithType(val, "ctaType", isString) || hasOptionalPropWithType(val, "ctaType", isNil))
  );
}

function isFinalFormTrackingObject(
  val: unknown,
  throwOnMissing = false
): val is FinalFormTrackingObject {
  try {
    return checkIsTypeOrThrow(val, isObject, throwOnMissing, "tracking value is not an object!") &&
      checkConditionOrThrow<object, { "usePageOfferId": boolean }>(
        val,
        x => hasPropWithType(x, "usePageOfferId", isBoolean),
        throwOnMissing,
        `tracking.usePageOfferId is not of type boolean`
      ) &&
      checkConditionOrThrow<object, { offerId?: string | Nil }>(
        val,
        x => (hasOptionalPropWithType(x, "offerId", isString) || hasOptionalPropWithType(val, "offerId", isNil)),
        throwOnMissing,
        `tracking?.offerId is not of type string | Nil`
      )
      && (hasOptionalPropWithType(val, "ctaText", isString) || hasOptionalPropWithType(val, "ctaText", isNil))
      && (hasOptionalPropWithType(val, "ctaType", isString) || hasOptionalPropWithType(val, "ctaType", isNil));
  }
  catch(err: unknown) {
    throw new Error(formatErrorMessage("isFinalFormTrackingObject", err), { cause: err });
  }
}

interface BaseSetupObject<TSetupType extends SetupType = SetupType> {
  type: TSetupType;
}

type BaseForm = {
  name: string
}

export type BaseFormSetupObject<
  TDataVersion extends InternalDataVersion,
  TFormSetupType extends FormSetupType = FormSetupType,
  TFormObject extends BaseForm & Record<string, any> = BaseForm & Record<string, any>
> = { type: TFormSetupType; form: TFormObject } & (TDataVersion extends "Create" ? { tracking?: CreateFormTrackingObject } : { tracking: FinalFormTrackingObject });

export type FormObjectWithDocument<
  TDataVersion extends InternalDataVersion,
> = BaseForm & {
  document: TDataVersion extends "Create"
    ? CreateDocumentObjectProperties
    : TDataVersion extends "Parse"
    ? ParsedDocumentObjectProperties
    : TDataVersion extends "Final"
    ? SelectedMultiResource<DocumentResource>
    : never
}

export interface FormObjectWithVideo<
  TDataVersion extends InternalDataVersion,
  TVideo extends VideoResource | VideoResourceWithDocs
> extends BaseForm {
  video: TDataVersion extends "Create"
    ? CreateVideoObjectProperties<TVideo>
    : TDataVersion extends "Parse"
    ? ParsedVideoObjectProperties<TVideo>
    : TDataVersion extends "Final"
    ? SelectedSingleResource<TVideo>
    : never;
}

type ResourceFormSetup<TDataVersion extends InternalDataVersion> = BaseFormSetupObject<TDataVersion, "ResourceForm", FormObjectWithDocument<TDataVersion>>;
type ContactFormSetup<TDataVersion extends InternalDataVersion> = BaseFormSetupObject<TDataVersion, "ContactForm">;
export interface YouTubeSetup extends BaseSetupObject {
  type: "YouTube";
  youTubeId: string;
}

export type CreateResourceFormSetup = ResourceFormSetup<"Create">;
type ParsedResourceFormSetup = ResourceFormSetup<"Parse">;
export type FinalResourceFormSetup = ResourceFormSetup<"Final">;
function isBaseSetupObject(val: unknown): val is BaseSetupObject {
  return isObject(val) && hasPropWithType(val, "type", isSetupType);
}

function isBaseFormSetupObject<TDataVersion extends InternalDataVersion>(
  val: unknown,
  dataVersion: TDataVersion,
  throwOnMissing = false
): val is BaseFormSetupObject<TDataVersion> {
  try {
    return (
      checkIsTypeOrThrow(
        val,
        isObject,
        throwOnMissing,
        "setup is not an object!"
      ) &&
      checkConditionOrThrow<object, { type: FormSetupType }>(
        val,
        (x) => hasPropWithType(x, "type", isFormSetupType),
        throwOnMissing,
        `setup does not have a property 'type' that matches a FormSetupType: ${FormSetupType.toString()}`
      ) &&
      (dataVersion === "Create"
        ? checkConditionOrThrow(
            val,
            (x) =>
              hasOptionalPropWithType(x, "tracking", isCreateFormTrackingObject),
            throwOnMissing,
            `setup does not have a property "tracking" of type CreateFormTrackingObject`
          )
        : checkConditionOrThrow(
            val,
            (x) => hasPropWithType(x, "tracking", isFinalFormTrackingObject),
            throwOnMissing,
            `setup does not have a property "tracking" of type FinalFormTrackingObject`
          )) &&
      checkConditionOrThrow<object, { form: object }>(
        val,
        (x) => hasPropWithType(x, "form", isObject),
        throwOnMissing,
        `setup does not have a property "form" of type object`
      )
    );
  } catch (err: unknown) {
    throw new Error(formatErrorMessage("isBaseFormSetupObject", err), { cause: err });
  }
}

export function isYoutubeSetup(val: unknown, throwOnMissing = false): val is YouTubeSetup {
  try {
    return checkIsTypeOrThrow(val, isBaseSetupObject, throwOnMissing, `"setup" is not of type "BaseSetupObject"`) &&
      checkConditionOrThrow(val.type, x => x === "YouTube", throwOnMissing, "type is not equal to 'YouTube'") &&
      checkConditionOrThrow(val, x => hasPropWithType(x, "youTubeId", isString), throwOnMissing, "setup does not have property 'youTubeId' of type string");
  } catch (err: unknown) {
    throw new Error(formatErrorMessage("isYoutubeSetup", err), { cause: err });
  }
}

function isFormObjectWithDocument<TDataVersion extends InternalDataVersion>(
  val: unknown,
  typeCheckFn: TypeCheckFn<
    TDataVersion extends "Create"
      ? CreateDocumentObjectProperties
      : TDataVersion extends "Parse"
      ? ParsedDocumentObjectProperties
      : TDataVersion extends "Final"
      ? SelectedMultiResource<DocumentResource>
      : never
  >
): val is FormObjectWithDocument<TDataVersion> {
  return isObject(val) && hasPropWithType(val, "document", typeCheckFn);
}

function isFormObjectWithVideo<
  TDataVersion extends InternalDataVersion,
  TVideo extends VideoResource | VideoResourceWithDocs
>(
  val: unknown,
  typeCheckFn: TypeCheckFn<
    TDataVersion extends "Create"
    ? CreateVideoObjectProperties<TVideo>
    : TDataVersion extends "Parse"
    ? ParsedVideoObjectProperties<TVideo>
    : TDataVersion extends "Final"
    ? SelectedSingleResource<TVideo> : never
  >
): val is FormObjectWithVideo<TDataVersion, TVideo> {
  return isObject(val) && hasPropWithType(val, "video", typeCheckFn);
}

function isCreateResourceFormSetup(
  val: unknown
): val is CreateResourceFormSetup {
  return (
    isBaseFormSetupObject(val, "Create") &&
    val.type === "ResourceForm" &&
    isFormObjectWithDocument(val.form, isCreateDocumentObjectProperties)
  );
}

function isParsedResourceFormSetup(
  val: unknown
): val is ParsedResourceFormSetup {
  return (
    isBaseFormSetupObject(val, "Parse") &&
    val.type === "ResourceForm" &&
    isFormObjectWithDocument(val.form, isParsedDocumentObjectProperties)
  );
}

function isFinalResourceFormSetup(val: unknown, throwOnMissing = false): val is FinalResourceFormSetup {
  try {
    return checkConditionOrThrow<unknown, BaseFormSetupObject<"Final">>(val, x => isBaseFormSetupObject<"Final">(x, "Final", throwOnMissing), throwOnMissing, `"setup" is not of type "BaseFormSetupObject<'Final'>"`) &&
      checkConditionOrThrow(val.type, x => x === "ResourceForm", throwOnMissing, "type is not equal to 'ResourceForm'") &&
      checkConditionOrThrow<BaseFormSetupObject<"Final">, BaseFormSetupObject<"Final"> & Record<"form", object>>(
        val,
        x => hasPropWithType(x, "form", isObject),
        throwOnMissing,
        "setup does not have property 'form' of type object"
      ) &&
      checkConditionOrThrow<object, SelectedMultiResource<DocumentResource>>(
        val.form,
        x => hasPropWithType(x, "document", (y => isSelectedMultiResource<DocumentResource>(y, isDocument, throwOnMissing)) as TypeCheckFn<SelectedMultiResource<DocumentResource>>),
        throwOnMissing,
        "'setup.form' is not of type SelectedMultiResource<DocumentResource>"
      );
  } catch (err: unknown) {
    throw new Error(formatErrorMessage("isFinalResourceFormSetup", err), { cause: err });
  }

}

export interface CreateVideoFormSetup extends BaseSetupObject, BaseFormSetupObject<"Create", "VideoForm", FormObjectWithVideo<"Create", VideoResourceWithDocs>> {
  type: "VideoForm";
  form: FormObjectWithVideo<"Create", VideoResourceWithDocs>;
}

interface ParsedVideoFormSetup extends BaseSetupObject, BaseFormSetupObject<"Parse", "VideoForm", FormObjectWithVideo<"Parse", VideoResourceWithDocs>> {
  type: "VideoForm";
  form: FormObjectWithVideo<"Parse", VideoResourceWithDocs>;
}
export interface FinalVideoFormSetup extends BaseSetupObject, BaseFormSetupObject<"Final", "VideoForm", FormObjectWithVideo<"Final", VideoResourceWithDocs>> {
  type: "VideoForm";
  form: FormObjectWithVideo<"Final", VideoResourceWithDocs> & FormObjectWithDocument<"Final">;
}

function isCreateVideoFormSetup(val: unknown): val is CreateVideoFormSetup {
  return (
    isBaseFormSetupObject(val, "Create") &&
    val.type === "VideoForm" &&
    hasPropWithType(val, "form", isObject) &&
    isFormObjectWithVideo(val.form, isCreateVideoWithDocsObjectProperties)
  );
}

function isParsedVideoFormSetup(val: unknown): val is ParsedVideoFormSetup {
  return (
    isBaseFormSetupObject(val, "Parse") &&
    val.type === "VideoForm" &&
    hasPropWithType(val, "form", isObject) &&
    isFormObjectWithVideo(val.form, isParsedVideoWithDocsObjectProperties)
  );
}


function isFinalVideoFormSetup(val: unknown, throwOnMissing = false): val is FinalVideoFormSetup {
  try {
    return checkConditionOrThrow<unknown, BaseFormSetupObject<"Final">>(
        val, x => isBaseFormSetupObject<"Final">(x, "Final", throwOnMissing), throwOnMissing, `"setup" is not of type "BaseFormSetupObject<'Final'>"`) &&
      checkConditionOrThrow(val.type, x => x === "VideoForm", throwOnMissing, "type is not equal to 'VideoForm'") &&
      checkConditionOrThrow<BaseFormSetupObject<"Final">, BaseFormSetupObject<"Final"> & Record<"form", object>>(
        val,
        x => hasPropWithType(x, "form", isObject),
        throwOnMissing,
        "setup does not have property 'form' of type object"
      ) &&
      checkConditionOrThrow<object, SelectedMultiResource<DocumentResource>>(
        val.form,
        x => hasPropWithType(x, "document", ((y: unknown) => isSelectedMultiResource<DocumentResource>(y, isDocument, throwOnMissing)) as TypeCheckFn<SelectedMultiResource<DocumentResource>>),
        throwOnMissing,
        `'setup.form' is not of type SelectedMultiResource<DocumentResource>`
      ) &&
      checkConditionOrThrow<object, SelectedMultiResource<DocumentResource>>(
        val.form, (x => {
          return isFormObjectWithVideo<"Final", VideoResourceWithDocs>(x,  (y: unknown) => isSelectedSingleResource<VideoResourceWithDocs>(y, isVideoWithDocs)); 
        }),
        throwOnMissing,
        "'setup.form' is not of type SelectedSingleResource<VideoResourceWithDocs>"
      );
  } catch (err: unknown) {
    throw new Error(formatErrorMessage("isFinalVideoFormSetup", err), { cause: err });
  }
}
export interface FinalPostEventFormSetup extends BaseSetupObject, BaseFormSetupObject<"Final", "PostEventForm", FormObjectWithVideo<"Final", VideoResource>> {
  type: "PostEventForm";
  form: FormObjectWithVideo<"Final", VideoResource> & FormObjectWithDocument<"Final">;
  gatingLevel: "Full Gate" | "Partial Gate" | "Ungated";
}
export function isFinalPostEventFormSetup(val: unknown, throwOnMissing = false): val is FinalPostEventFormSetup {
  try {
    return checkConditionOrThrow<unknown, BaseFormSetupObject<"Final">>(
      val, x => isBaseFormSetupObject<"Final">(x, "Final", throwOnMissing), throwOnMissing, `"setup" is not of type "BaseFormSetupObject<'Final'>"`) &&
      checkConditionOrThrow(val.type, x => x === "PostEventForm", throwOnMissing, "type is not equal to 'PostEventForm'") &&
      checkConditionOrThrow<BaseFormSetupObject<"Final">, BaseFormSetupObject<"Final"> & Record<"form", object>>(
        val,
        x => hasPropWithType(x, "form", isObject),
        throwOnMissing,
        "setup does not have property 'form' of type object"
      ) &&
      checkConditionOrThrow<object, SelectedMultiResource<DocumentResource> | SelectedMultiResource<VideoResource>>(
        val.form,
        x => {
          return hasPropWithType(
            x,
            "document",
            (y: unknown) => isSelectedMultiResource<DocumentResource>(y, isDocument, false)
          ) ||
          isFormObjectWithVideo<"Final", VideoResource>(
            x,
            (y: unknown): y is SelectedSingleResource<VideoResource> => isSelectedSingleResource<VideoResource>(y, isVideoOnly)
          );
        }, throwOnMissing,
        `'setup.form' is not of type SelectedMultiResource<DocumentResource> | SelectedMultiResource<VideoResource>`
      );
  } catch (err: unknown) {
    throw new Error(formatErrorMessage("isFinalPostEventFormSetup", err), { cause: err });
  }
}

export interface CreateGatedVideoFormSetup extends BaseSetupObject, BaseFormSetupObject<"Create", "GatedVideoForm", FormObjectWithVideo<"Create", VideoResource>> {
  type: "GatedVideoForm";
  form: FormObjectWithVideo<"Create", VideoResource>;
}
interface ParsedGatedVideoFormSetup extends BaseSetupObject, BaseFormSetupObject<"Parse", "GatedVideoForm", FormObjectWithVideo<"Parse", VideoResource>> {
  type: "GatedVideoForm";
  form: FormObjectWithVideo<"Parse", VideoResource>;
}
export interface FinalGatedVideoFormSetup extends BaseSetupObject, BaseFormSetupObject<"Final", "GatedVideoForm", FormObjectWithVideo<"Final", VideoResource>> {
  type: "GatedVideoForm";
  form: FormObjectWithVideo<"Final", VideoResource>;
}

function isCreateGatedVideoFormSetup(
  val: unknown
): val is CreateGatedVideoFormSetup {
  return (
    isBaseFormSetupObject(val, "Create") &&
    val.type === "GatedVideoForm" &&
    hasPropWithType(val, "form", isObject) &&
    isFormObjectWithVideo(val.form, isCreateVideoObjectProperties)
  );
}

function isParsedGatedVideoFormSetup(
  val: unknown
): val is ParsedGatedVideoFormSetup {
  return (
    isBaseFormSetupObject(val, "Create") &&
    val.type === "GatedVideoForm" &&
    hasPropWithType(val, "form", isObject) &&
    isFormObjectWithVideo(val.form, isParsedVideoObjectProperties)
  );
}

function isFinalGatedVideoFormSetup(val: unknown, throwOnMissing = false): val is FinalGatedVideoFormSetup {
  try {
    return checkConditionOrThrow<unknown, BaseFormSetupObject<"Final">>(val, x => isBaseFormSetupObject<"Final">(x, "Final", throwOnMissing), throwOnMissing, `"setup" is not of type "BaseFormSetupObject<'Final'>"`) &&
      checkConditionOrThrow(val.type, x => x === "GatedVideoForm", throwOnMissing, "type is not equal to 'GatedVideoForm'") &&
      checkConditionOrThrow<BaseFormSetupObject<"Final">, BaseFormSetupObject<"Final"> & Record<"form", object>>(
        val,
        x => hasPropWithType(x, "form", isObject),
        throwOnMissing,
        "setup does not have property 'form' of type object"
      ) &&
      checkConditionOrThrow<object, SelectedMultiResource<DocumentResource>>(
        val.form, (x => {
          return isFormObjectWithVideo<"Final", VideoResource>(x, ((y) => isSelectedSingleResource<VideoResource>(y, isVideoOnly)) as TypeCheckFn<SelectedSingleResource<VideoResource>>); }),
        throwOnMissing,
        "'setup.form' is not of type SelectedSingleResource<VideoResource>"
      );
  }
  catch (err: unknown) {
    throw new Error(formatErrorMessage("isFinalGatedVideoFormSetup", err), { cause: err });
  }
}


export type FinalContactFormSetup = ContactFormSetup<"Final">;

function isFinalContactFormSetup(val: unknown, throwOnMissing = false): val is FinalContactFormSetup {
  return (
    isBaseFormSetupObject(val, "Final", throwOnMissing) &&
    val.type === "ContactForm" &&
    hasPropWithType(val, "form", isObject)
  );
}

export type AnyCreateSetupObject =
  | YouTubeSetup
  | CreateResourceFormSetup
  | CreateVideoFormSetup
  | CreateGatedVideoFormSetup;

export type AnyParsedSetupObject =
  | YouTubeSetup
  | ParsedResourceFormSetup
  | ParsedVideoFormSetup
  | ParsedGatedVideoFormSetup;

export type AnyAppStateFinalSetupObject =
  | YouTubeSetup
  | FinalResourceFormSetup
  | FinalVideoFormSetup
  | FinalGatedVideoFormSetup
  | FinalPostEventFormSetup;

export type AnyOtherFinalSetupObject = FinalContactFormSetup;

export type AnyFormFinalSetupObject =
  | FinalResourceFormSetup
  | FinalVideoFormSetup
  | FinalGatedVideoFormSetup
  | FinalContactFormSetup
  | FinalPostEventFormSetup;

export function isAnyFormFinalSetupObject(value: unknown,  throwOnMissing = false): value is AnyFormFinalSetupObject {
  try {
    if (checkConditionOrThrow(value, isObject, throwOnMissing, "'setup' value is not an object")) {
      const errors: unknown[] = [];
      try { if (isFinalResourceFormSetup(value, throwOnMissing)) { return true; } } catch (err: unknown) { errors.push(err); }
      try { if (isFinalVideoFormSetup(value, throwOnMissing)) { return true; } } catch (err: unknown) { errors.push(err); }
      try { if (isFinalGatedVideoFormSetup(value, throwOnMissing)) { return true; } } catch (err: unknown) { errors.push(err); }
      try { if (isFinalContactFormSetup(value, throwOnMissing)) { return true; } } catch (err: unknown) { errors.push(err); }
      try { if (isFinalPostEventFormSetup(value, throwOnMissing)) { return true; } } catch (err: unknown) { errors.push(err); }
      if (errors.length > 0 && throwOnMissing) {
        throw new Error(formatErrorMessage("setup is not of type 'AnyFormFinalSetupObject'", ...errors), { cause: errors });
      }
    }
    return false;
  } catch (err: unknown) {
    throw new Error(formatErrorMessage("isAnyFormFinalSetupObject", err), { cause: err });
  }
}

export type CreateSingleSetupObject<SetupObjectTypes extends AnyCreateSetupObject = AnyCreateSetupObject> = SetupObjectTypes;
type ParsedSingleSetupObject<SetupObjectTypes extends AnyParsedSetupObject = AnyParsedSetupObject> = SetupObjectTypes; 
export type FinalAppStateSingleSetupObject<SetupObjectTypes extends AnyAppStateFinalSetupObject = AnyAppStateFinalSetupObject> = SetupObjectTypes;
export type FinalOtherSingleSetupObject<SetupObjectTypes extends AnyOtherFinalSetupObject = AnyOtherFinalSetupObject> = SetupObjectTypes;

function isCreateSingleSetupObject(value: unknown): value is CreateSingleSetupObject {
  return isBaseSetupObject(value) && (
    isYoutubeSetup(value) || isCreateResourceFormSetup(value) || isCreateVideoFormSetup(value) || isCreateGatedVideoFormSetup(value)
  );
}
function isParsedSingleSetupObject(value: unknown): value is ParsedSingleSetupObject {
  return isBaseSetupObject(value) && (
    isYoutubeSetup(value) || isParsedResourceFormSetup(value) || isParsedVideoFormSetup(value) || isParsedGatedVideoFormSetup(value)
  );
}

function isFinalAppStateSingleSetupObject(value: unknown, throwOnMissing = false): value is FinalAppStateSingleSetupObject {
  try {
    if (checkConditionOrThrow(value, isBaseSetupObject, throwOnMissing, "'setup' value is not of type BaseSetupObject")) {
      const errors: unknown[] = [];
      try { if (isYoutubeSetup(value, throwOnMissing)) { return true; } } catch (err: unknown) { errors.push(err); }
      try { if (isFinalResourceFormSetup(value, throwOnMissing)) { return true; } } catch (err: unknown) { errors.push(err); }
      try { if (isFinalVideoFormSetup(value, throwOnMissing)) { return true; } } catch (err: unknown) { errors.push(err); }
      try { if (isFinalGatedVideoFormSetup(value, throwOnMissing)) { return true; } } catch (err: unknown) { errors.push(err); }
      if (errors.length > 0 && throwOnMissing) {
        throw new Error(formatErrorMessage("setup is not of type 'FinalAppStateSingleSetupObject'", errors), { cause: errors });
      }
    }
    return false;
  } catch (err: unknown) {
    throw new Error(formatErrorMessage("isFinalAppStateSingleSetupObject", err), { cause: err });
  }
}

function isFinalOtherSingleSetupObject(value: unknown): value is FinalOtherSingleSetupObject {
  return isBaseSetupObject(value) && isFinalContactFormSetup(value);
}

interface BaseMultiSetupObject extends BaseSetupObject {
  setupName: string;
}
function isBaseMultiSetupObject(value: unknown): value is BaseMultiSetupObject {
  return isBaseSetupObject(value) && hasPropWithType(value, "setupName", isString);
}

export type CreateMultipleSetupObject<SetupObjectTypes extends AnyCreateSetupObject = AnyCreateSetupObject, TSetup extends CreateSingleSetupObject<SetupObjectTypes> = CreateSingleSetupObject<SetupObjectTypes>> = BaseMultiSetupObject & TSetup;
type ParsedMultipleSetupObject<SetupObjectTypes extends AnyParsedSetupObject = AnyParsedSetupObject, TSetup extends ParsedSingleSetupObject<SetupObjectTypes> = ParsedSingleSetupObject<SetupObjectTypes>> = BaseMultiSetupObject & TSetup;
export type FinalMultipleSetupObject<SetupObjectTypes extends AnyAppStateFinalSetupObject = AnyAppStateFinalSetupObject, TSetup extends FinalAppStateSingleSetupObject<SetupObjectTypes> = FinalAppStateSingleSetupObject<SetupObjectTypes>> = BaseMultiSetupObject & TSetup;

function isCreateMultipleSetupObject(value: unknown): value is CreateMultipleSetupObject {
  return  isBaseMultiSetupObject(value) && isCreateSingleSetupObject(value);
}
function isParsedMultipleSetupObject(value: unknown): value is ParsedMultipleSetupObject {
  return  isBaseMultiSetupObject(value) && isParsedSingleSetupObject(value);
}
function isFinalMultipleSetupObject(value: unknown, throwOnMissing = false): value is FinalMultipleSetupObject {
  try {
    return isFinalAppStateSingleSetupObject(value, throwOnMissing) && isBaseMultiSetupObject(value);
  } catch (err: unknown) {
    throw new Error(formatErrorMessage("isFinalMultipleSetupObject", err), { cause: err });
  }
}

export interface CreateWidgetData<SetupObjectTypes extends AnyCreateSetupObject = AnyCreateSetupObject, AllowSingleItemInSetup extends boolean = true> {
  widget: Widget;
  setup:
    | (
      AllowSingleItemInSetup extends true
      ? CreateSingleSetupObject<SetupObjectTypes> | [CreateSingleSetupObject<SetupObjectTypes>]
      : [CreateSingleSetupObject] 
    ) 
    | [CreateMultipleSetupObject<SetupObjectTypes>, ...CreateMultipleSetupObject<SetupObjectTypes>[]];
}

interface ParsedWidgetData<SetupObjectTypes extends AnyParsedSetupObject = AnyParsedSetupObject, SingleItemSetup = false> {
  widget: Widget;
  setup: SingleItemSetup extends true
    ? (ParsedSingleSetupObject<SetupObjectTypes> | ParsedMultipleSetupObject<SetupObjectTypes>)
    : ([ParsedSingleSetupObject<SetupObjectTypes>] | [ParsedMultipleSetupObject<SetupObjectTypes>, ...ParsedMultipleSetupObject<SetupObjectTypes>[]]);
}

export interface FinalWidgetData<SetupObjectTypes extends AnyAppStateFinalSetupObject | AnyOtherFinalSetupObject = AnyAppStateFinalSetupObject> {
  widget: Widget;
  setup: SetupObjectTypes extends AnyAppStateFinalSetupObject
    ? (FinalAppStateSingleSetupObject<SetupObjectTypes> | FinalMultipleSetupObject<SetupObjectTypes>)
    : (SetupObjectTypes extends AnyOtherFinalSetupObject? FinalOtherSingleSetupObject<SetupObjectTypes> : never)
}

export function isCreateWidgetData<AllowSingleItemInSetup extends boolean>(value: unknown, allowSingleItemInSetup: AllowSingleItemInSetup): value is CreateWidgetData<AnyCreateSetupObject, AllowSingleItemInSetup> {
  return isObject(value) &&
    hasPropWithType(value, "widget", isWidget) &&
    hasPropWithType(value, "setup", (x => {
      return (allowSingleItemInSetup && isCreateSingleSetupObject(x)) || (
        isArray(x) &&
        x.length > 0 && (
          (x.length === 1 && isArrayOfType<CreateSingleSetupObject>(x, isCreateSingleSetupObject)) ||
          isArrayOfType<CreateMultipleSetupObject>(x, isCreateMultipleSetupObject)
        )
      );
    }) as TypeCheckFn<CreateWidgetData<AnyCreateSetupObject, AllowSingleItemInSetup>["setup"]>);
}

function isParsedWidgetData<SingleItemSetup extends boolean>(value: unknown, singleItemInSetup: SingleItemSetup): value is ParsedWidgetData<AnyParsedSetupObject, SingleItemSetup> {
  return isObject(value) &&
    hasPropWithType(value, "widget", isWidget) &&
    hasPropWithType(value, "setup", (x => {
      return (singleItemInSetup && isParsedSingleSetupObject(x)) || (
        isArray(x) && x.length > 0 && (
          (x.length === 1
            ? isArrayOfType<ParsedSingleSetupObject>(x, isParsedSingleSetupObject)
            : isArrayOfType<ParsedMultipleSetupObject>(x, isParsedMultipleSetupObject)
          )
        ));
    }) as TypeCheckFn<ParsedWidgetData<AnyParsedSetupObject, SingleItemSetup>["setup"]>);
}

function isObjectWithWidgetProp(value: object, throwOnMissing: boolean): value is object & Record<"widget", Widget> {
  return checkConditionOrThrow<object, object & Record<"widget", Widget>>(value, (x => hasPropWithType(x, "widget", isWidget)), throwOnMissing, "'value.widget' is not a widget object: " + JSON.stringify(value));
}

function isObjectWithFinalSetupProp(value: object, throwOnMissing = false): value is object & Record<"setup", AnyAppStateFinalSetupObject[] | AnyOtherFinalSetupObject[]> {
  try {
    if ("setup" in value) {
      if (isArray(value.setup)) {
        if (value.setup.length === 1) {
          return checkConditionOrThrow<any[], AnyAppStateFinalSetupObject[] | AnyOtherFinalSetupObject[]>(
            value.setup,
            x => (isArrayOfType<AnyAppStateFinalSetupObject>(x, y => isFinalAppStateSingleSetupObject(y, throwOnMissing)) || isArrayOfType<AnyOtherFinalSetupObject>(x, isFinalOtherSingleSetupObject)),
            throwOnMissing,
            "'value.setup' is not an array of type AnyAppStateFinalSetupObject or AnyOtherFinalSetupObject: " + JSON.stringify(value.setup)
          );
        }
        else {
          return checkConditionOrThrow<any[], FinalMultipleSetupObject[]>(value.setup, (x => isArrayOfType(x, isFinalMultipleSetupObject)),
            throwOnMissing,
            "'value.setup' is not an array of type FinalMultipleSetupObject: " + JSON.stringify(value.setup)
          );
        }
      }
      else {
        return checkConditionOrThrow<unknown, AnyAppStateFinalSetupObject | AnyOtherFinalSetupObject>(
          value.setup,
          x => {
            return isFinalAppStateSingleSetupObject(x, throwOnMissing) || isFinalOtherSingleSetupObject(x);
          },
          throwOnMissing,
          "'value.setup' is not an object of type AnyAppStateFinalSetupObject or AnyOtherFinalSetupObject: " + JSON.stringify(value.setup)
        );
      }
    }
    else {
      throw new Error("'value.setup' is not defined");
    }
  } catch (err: unknown) {
    throw new Error(formatErrorMessage("isObjectWithFinalSetupProp", err), { cause: err });
  }

}

export function isFinalWidgetData(value: unknown, throwOnMissing = false): value is FinalWidgetData<AnyAppStateFinalSetupObject | AnyOtherFinalSetupObject> {
  try {
    return checkIsTypeOrThrow(value, isObject, throwOnMissing, "'value' is not an object.") &&
      isObjectWithWidgetProp(value, throwOnMissing) &&
      isObjectWithFinalSetupProp(value, throwOnMissing);
  } catch (err: unknown) {
    throw new Error(formatErrorMessage("isFinalWidgetData", err), { cause: err });
  }
}

type SetupTypeToFinalSetupObject<TSetup extends SetupType = SetupType> =
  TSetup extends "YouTubeSetup"
  ? YouTubeSetup
  : TSetup extends "ResourceForm"
  ? ResourceFormSetup<"Final">
  : TSetup extends "VideoForm"
  ? FinalVideoFormSetup
  : TSetup extends "PostEventForm"
  ? FinalPostEventFormSetup
  : TSetup extends "GatedVideoForm"
  ? FinalGatedVideoFormSetup
  : TSetup extends "ContactForm"
  ? ContactFormSetup<"Final">
  : never;

export type FormWidgetData<TSetupType extends FormSetupType = FormSetupType> = FinalWidgetData<SetupTypeToFinalSetupObject<TSetupType>>;
export function isFormWidgetData<TSetupType extends FormSetupType>(
  value: unknown,
  setupType?: TSetupType | TSetupType[],
  throwOnMissing = false
): value is (typeof setupType extends Nil? FormWidgetData : FormWidgetData<TSetupType>) {
  try {
    setupType ??= FormSetupType as unknown as TSetupType[];
    setupType = (isArray(setupType) ? setupType : [setupType]);
    return checkIsTypeOrThrow(value, isObject, throwOnMissing, "'value' is not an object.") &&
      isObjectWithWidgetProp(value, throwOnMissing) &&
      ("setup" in value) &&
      isAnyFormFinalSetupObject(value.setup, throwOnMissing) &&
      checkConditionOrThrow<AnyFormFinalSetupObject, SetupTypeToFinalSetupObject<TSetupType>>(
        value.setup,
        (s) => (setupType as string[]).includes(s.type),
        throwOnMissing,
        `setup type of type ${value.setup.type} does not match any of the provided types in ${setupType.toString()}`
      );
  } catch (err: unknown) {
    throw new Error(formatErrorMessage("isFormWidgetData", err), {cause: err });
  }
}

function assignDefaultValues(dataObj: CreateWidgetData<AnyCreateSetupObject, true>) {
  dataObj.setup = isArray(dataObj.setup) ? dataObj.setup : [dataObj.setup];

  // Iterate through each setup object and assign default values
  dataObj.setup.forEach((setup) => {
    // Defaults for "form" property
    if (isBaseFormSetupObject(setup, "Create")) {
      const form = setup.form;
      // Defaults for "document" property
      if (isFormObjectWithDocument(form, isCreateDocumentObjectProperties)) {
        const document = form.document;
        // "selectedListWillBeSingleDocument" defaults to false unless sourceList contains only one document and sourceList is not being filtered
        document.selectedListWillBeSingleDocument ??=
          (document.sourceList || []).length === 1 &&
          document.isFilteredOrChosen !== true;
        // "isFilteredOrChosen" defaults to false
        document.isFilteredOrChosen ??= false;

        // "description" defaults to "" for all documents
        const sourceList = document.sourceList;
        sourceList.forEach((doc) => {
          doc.description = doc.description ?? "";
        });
      }

      // Defaults for "video" property

      if (isFormObjectWithVideo(form, isCreateVideoObjectProperties)) {
        const video = (form.video as CreateVideoObjectProperties<VideoResource | VideoResourceWithDocs>);
        // "isFilteredOrChosen" defaults to false
        video.isFilteredOrChosen = video.isFilteredOrChosen ?? false;
      }

      if (isBaseFormSetupObject(setup, "Create")) {
        // Set the default "tracking.usePageOfferId" setting to false
        setup.tracking ??= { usePageOfferId: false };
        setup.tracking.usePageOfferId ??= false;
      }
    }
  });
  return dataObj as unknown as CreateWidgetData<AnyCreateSetupObject, false>;
}

function removeUnwantedProps(setup: CreateSingleSetupObject) {
  if (isObject(setup) && ["YouTube", "GatedVideoForm"].includes(setup.type)) {
    if (hasPropWithType(setup, "form", isObject) && hasPropWithType(setup.form, "video", isObject)) {
      setup.form.video.sourceList.forEach((el) => {
        if (hasPropWithType(el, "documentList", isArray)) {
          delete (el as Partial<typeof el>).documentList;
        }
      });
    }
  }
}

const getModifiers = {
  setupName(widgetDataObj: ParsedWidgetData, setupName?: string): ParsedWidgetData<AnyParsedSetupObject, true> {
    const { setup, ...remainingWidgetData } = widgetDataObj;

    if (setup.length === 1) {
      return { setup: setup[0], ...remainingWidgetData };
    } else {
      const setupItem = setup.find(
        (s) => ("setupName" in s) && s.setupName === setupName
      );
      if (isNil(setupItem)) {
        throw new Error("Couldn't find setupName: " + (setupName ?? "NULL"));
      }
      return {
        setup: setupItem,
        ...remainingWidgetData,
      };
    }
  },
  videoSelector(widgetDataObj: ParsedWidgetData<AnyParsedSetupObject, true> & Partial<FinalWidgetData<AnyAppStateFinalSetupObject>>, videoId: string) {
    const { setup, ...remainingWidgetData } = widgetDataObj;
    if (hasPropWithType(setup, "form", isObject) && isFormObjectWithVideo(setup.form, isParsedVideoObjectProperties)) {
      const { form, ...remainingSetup } = setup;
      const video = (form.video as ParsedVideoObjectProperties<VideoResource | VideoResourceWithDocs>).sourceList.find((vid) => {
        return vid.id === videoId;
      }) as VideoResource | VideoResourceWithDocs | Nil;
      if (isNil(video)) {
        throw new Error("Could not find video with ID: " + videoId);
      }
      (form.video as unknown as SelectedSingleResource<VideoResource | VideoResourceWithDocs>).selected = video;
      if ("documentList" in video) {
        return {
          ...remainingWidgetData,
          setup: {
            ...remainingSetup,
            form: {
              ...form,
              document: { selected: video.documentList },
            },
          },
        };
      }
      return {
        ...remainingWidgetData,
        setup: { ...remainingSetup, form },
      };
    }
    return widgetDataObj;
  },
  documentSelector(widgetDataObj: ParsedWidgetData<AnyParsedSetupObject, true>, documentIds: string[]) {
    if ("form" in widgetDataObj.setup && "document" in widgetDataObj.setup.form) {
      if (documentIds.length === 0) {
        ((widgetDataObj.setup as unknown as FinalResourceFormSetup).form.document).selected =
          [...(widgetDataObj.setup as unknown as ParsedResourceFormSetup).form.document.sourceList];
      } else {
        const documents = (widgetDataObj.setup as unknown as ParsedResourceFormSetup).form.document.sourceList.filter((doc) => {
          return documentIds.includes(doc.id);
        });
        (widgetDataObj.setup as unknown as FinalResourceFormSetup).form.document.selected = documents;
      }
    }
    return widgetDataObj;
  },

  cleanup(widgetDataObj: FinalWidgetData<AnyAppStateFinalSetupObject>) {
    const setup = widgetDataObj.setup;

    if (isBaseFormSetupObject(setup, "Parse")) {
      const tracking = setup.tracking;
      if (tracking && "usePageOfferId" in tracking && tracking.usePageOfferId === true) {
        tracking.offerId = window.AppState.PageOfferId as string | Nil;
      }
      if (isParsedVideoFormSetup(setup) || isParsedGatedVideoFormSetup(setup)) {
        if ("sourceList" in setup.form.video) {
          if ("selected" in setup.form.video === false) {
            if ((setup.form.video as any).sourceList.length === 1) {
              (setup.form.video as unknown as SelectedSingleResource<VideoResource | VideoResourceWithDocs>).selected = ((setup.form.video as any).sourceList[0] as (VideoResource | VideoResourceWithDocs));
              const selected = (setup.form.video as SelectedSingleResource<VideoResource | VideoResourceWithDocs>).selected;
              const docList = "documentList" in selected ? selected.documentList : [];

              if (docList.length > 0) {
                (setup as FinalVideoFormSetup).form.document = { selected: docList };
              }
            }
            else {
              throw new Error("WidgetData.Get: Videos were not filtered, more than one is being returned.");
            }
          }
          delete (setup.form.video as Partial<ParsedVideoObjectProperties<VideoResource>>).sourceList;
          delete (setup.form.video as unknown as { documentList: any }).documentList;
        }
        if ("offerId" in tracking === false && isArray(setup.form.video.selected) === false) {
          tracking.offerId = setup.form.video.selected.salesforceOfferId;
        }
        delete setup.form.video.selected.salesforceOfferId;
      }
      else if (isParsedResourceFormSetup(setup)) {
        if ("sourceList" in setup.form.document) {
          if ("selected" in setup.form.document === false) {
            (setup.form.document as unknown as SelectedMultiResource<DocumentResource>).selected = [...((setup.form.document as any).sourceList as DocumentResource[])];
          }
          delete (setup.form.document as Partial<ParsedDocumentObjectProperties>).sourceList;
        }
        if ("offerId" in tracking === false && setup.form.document.selectedListWillBeSingleDocument) {
          tracking.offerId = setup.form.document.selected[0].salesforceOfferId;
        }
        delete (setup.form.document as Partial<ParsedDocumentObjectProperties>).selectedListWillBeSingleDocument;
        delete (setup.form.document as Partial<ParsedDocumentObjectProperties>).isFilteredOrChosen;
        // [setup.form.document.selected].flat().forEach((doc) => {
        //   delete doc.salesforceOfferId;
        // });
      }
    }
    return setup as unknown as FinalWidgetData;
  },
} as const;

type ModifierKeys = keyof typeof getModifiers;

export type ModifiersObj = {
  [K in ModifierKeys]?: Parameters<typeof getModifiers[K]>[1] | undefined
}

const rawDataArray: ParsedWidgetData[] = [];

export function AddWidgetData<SetupObject extends AnyCreateSetupObject = AnyCreateSetupObject> (widgetDataObj: CreateWidgetData<SetupObject>) {
  if (isNil(widgetDataObj)) {
    console.error(
      "WidgetData.Add: Main Parameter 'widgetDataObj' <object> is not set."
    );
    return;
  }

  else if (isCreateWidgetData(widgetDataObj, true) === false) {
    throw new Error("WidgetData doesn't match the type definition");
  }

  // Assign default values before checking for required props
  const parsed = assignDefaultValues(widgetDataObj);
  // Check top level widgetDataObj requirements are met
  if (hasPropWithType(parsed, "setup", isArray)) {
    parsed.setup.forEach((setup) => {
      if (hasPropWithType(setup, "tracking", isObject) && hasPropWithType(setup.tracking, "salesforce", isObject)) {
        setup.tracking = { ...setup.tracking.salesforce, ...setup.tracking };
      }
      removeUnwantedProps(setup);
    });
  }

  rawDataArray.push(parsed as ParsedWidgetData);

  if (isParsedWidgetData(parsed, false) === false) {
    throw new Error("Parsed widget data does not match the type definition.");
  }
}

export function GetWidgetData(widgetInstanceId: string, modifiersObj?: ModifiersObj | undefined): FinalWidgetData {
  modifiersObj = modifiersObj || {};
  const originalWidgetDataObj = rawDataArray.find(function (data) {
    return data.widget.instanceId === widgetInstanceId;
  });
  // Clone the widget data since we're making changes to the result
  const rawWidgetDataObj = JSON.parse(
    JSON.stringify(originalWidgetDataObj)
  ) as ParsedWidgetData;

  const widgetDataObj = getModifiers.setupName(rawWidgetDataObj, modifiersObj.setupName);
  if (hasPropWithType(modifiersObj, "setupName", isString)) {
    delete (modifiersObj as Partial<ModifiersObj>).setupName;
  }

  // Iterate through passed modifiers
  if (isObject(modifiersObj)) {
    (Object.keys(modifiersObj) as ModifierKeys[]).forEach((key: ModifierKeys) => {
      if (key in getModifiers && modifiersObj && (isNil(modifiersObj?.[key]) === false)) {
        (getModifiers[key] as (...args: any[]) => void)(
          widgetDataObj,
          modifiersObj[key]
        );
      } else {
        console.error(
          `WidgetData.Get: getModifiers does not contain the key '${key}'`
        );
      }
    });
  }

  // Cleanup modifier
  getModifiers.cleanup(widgetDataObj as FinalWidgetData);

  if (isFinalWidgetData(widgetDataObj) === false) {
    throw new Error("WidgetData is not formatted correctly");
  }

  return widgetDataObj as FinalWidgetData<AnyAppStateFinalSetupObject>;
}

export function GetRawWidgetData () {
  return rawDataArray;
}


export interface IAppStateWidgetData {
  Add: typeof AddWidgetData;
  Get: typeof GetWidgetData;
  __getRawWidgetData: typeof GetRawWidgetData;
}
