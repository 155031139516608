import { default as i18next, type ResourceKey, type CallbackError } from "i18next";
import I18NextVue from "i18next-vue";
import { default as resourcesToBackend } from "i18next-resources-to-backend";
import type { App } from "vue";
import { BrowserEnv } from "../../utils/browser_env.js";
import { LocaleConvert, type LanguageCountryLocale, type SiteCountryCode } from "../../types-and-definitions/locale-defs/Locales.js";
import { TranslationNamespace } from "./settings/translation-namespaces.js";
import type { ILogger } from "../../utils/Logger/ILogger.js";
import { Logger } from "../../utils/Logger/Logger.js";
import { isArray, isString } from "@mcwd/typescript-type-guards";

function cleanupLogArgs(args: unknown[]) {
  if (isArray(args) && args.length > 0 && isString(args[0])) {
    args[0] = args[0].replace(/^i18next::/, "");
  }
  return args;
}

function createI18Next(logger: ILogger) {
  const internalLoggerOverride = logger.getChildLogger({
    tags: { service: 'i18next-internal' },
    prefixStrings: ["i18next"],
    prefixStyles: [Logger.StylePresets.ButtonLabel({ 'background-color': "#ff9f1a" })]
  });
  const i18nextInstance = i18next.createInstance()
  .use({
    type: 'logger',
    log(args: unknown[] = []) { return internalLoggerOverride.log(...cleanupLogArgs(args)); },
    warn(args: unknown[] = []) { return internalLoggerOverride.warn(...cleanupLogArgs(args)); },
    error(args: unknown[] = []) { return internalLoggerOverride.error(...cleanupLogArgs(args)); }
  })
  .use(
    resourcesToBackend((language: string, namespace: string, callback) => {
      const langFolder = language.toLowerCase() as Lowercase<LanguageCountryLocale | 'en'>;
      import(`/assets/js/marketo/locale/lang/${langFolder}/${namespace}.min.js`)
        .then(({ default: resources }: { default: ResourceKey | boolean | null | undefined }) => {
          logger.withTags({ verboseDebug: "lang" }).bindDebugOptions({formatString: "%s%O"})(langFolder + "/" + namespace+ " translations", resources);
          callback(null, resources);
        })
        .catch((error) => {
          logger.warn(`ERROR => ${langFolder}/${namespace} translations: `, error);
          callback(error as CallbackError, null);
        });
    })
  );
  return i18nextInstance;
}

async function Initializei18Next(lang: SiteCountryCode, logger: ILogger) {
  const langCode = LocaleConvert.FromSiteCountryCode.ToLanguageCountryLocale(lang, true);
  logger.info(`Language is "${langCode}"`);

  const i18nextInstance = createI18Next(logger);

  // init i18next. For all options read: https://www.i18next.com/overview/configuration-options
  await i18nextInstance
    .init({
      lng: langCode.toLowerCase(),
      debug: BrowserEnv.branch === "dev",
      load: "currentOnly",
      fallbackLng: "en-US",
      supportedLngs: ["en", "en-US", "en-AU", "en-GB", "fr-FR", "ja-JP", "ko-KR"] satisfies (LanguageCountryLocale | "en")[],
      partialBundledLanguages: true,
      ns: [...TranslationNamespace],
      keySeparator: false,
      nsSeparator: ":"
    });
  return i18nextInstance;
}
export async function useI18NextVue(app: App, lang: SiteCountryCode, logger: ILogger) {
  const translationsLogger = logger.getChildLogger({ tags: { formService: "translations" } });

  const i18nextInstance = await Initializei18Next(lang, logger);
  i18nextInstance.on("failedLoading", (lng: string, ns: string, msg: string) => {
    translationsLogger.error(`Failed to load translations: `, {lng, ns, msg});
  });
  i18nextInstance.on('missingKey', (lngs: readonly string[], namespace: string, key: string, res: string) => {
    translationsLogger.error(`Missing Key for translation: `, { lngs, namespace, key, res });
  });
  i18nextInstance.on("initialized", (options) => {
    translationsLogger.debug("Initialized i18next", options);
  });

  app.use(I18NextVue, { i18next: i18nextInstance });
  return app;
}